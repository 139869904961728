import { type FC } from 'react'

import { ChevronLeftIcon } from '@heroicons/react/24/solid'
import { Tooltip } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import type {
  FindPaginatedChangeLogs,
  UpdateChangelogInput,
} from 'types/graphql'

import Button from '../Library/Button/Button'
import DocumentEditor from '../Library/DocumentEditor/DocumentEditor'
import Switch from '../Library/Switch/Switch'

export type ChangeLogType =
  FindPaginatedChangeLogs['changelogs']['nodes'][number]

interface EditChangeLogItemProps {
  changelog: ChangeLogType
  onDeleteItem: (id: number) => void
  onUpdateItem: (id: number, input: UpdateChangelogInput) => void
  setEditId: (id: number) => void
}

const EditChangelogItem: FC<EditChangeLogItemProps> = ({
  changelog,
  onDeleteItem,
  onUpdateItem,
  setEditId,
}) => {
  return (
    <>
      <div className="mb-10 mt-8 flex items-center justify-between px-5">
        <div className="ml-5 flex items-center gap-3">
          <ChevronLeftIcon
            className="h-6 w-6 cursor-pointer text-gray-500"
            onClick={() => setEditId(null)}
          />
          <DateTimePicker
            label="Post Date"
            value={dayjs(changelog?.postDate)}
            onChange={(date) => {
              onUpdateItem(changelog.id, { postDate: date.toISOString() })
            }}
            className="bg-white"
          />
        </div>
        <div className="ml-5 flex items-center gap-5">
          <Tooltip
            title={
              changelog?.status === 'PUBLISHED'
                ? 'Switch to draft (this will make the item invisible to customers)'
                : 'Publish Item (this will be visible to everyone)'
            }
          >
            <div>
              <Switch
                checked={changelog?.status === 'PUBLISHED'}
                onChange={(e) => {
                  onUpdateItem(changelog.id, {
                    status: e.target.checked ? 'PUBLISHED' : 'DRAFT',
                  })
                }}
                size="medium"
              />
            </div>
          </Tooltip>

          <Button
            onClick={() => onDeleteItem(changelog.id)}
            loading={false}
            className="w-40 bg-red-600 hover:bg-red-700"
          >
            Delete Post
          </Button>
        </div>
      </div>

      <div className="mx-auto w-[716px] p-8">
        <DocumentEditor
          readerMode={false}
          defaultValue={changelog?.content as any}
          holder={`editor-${changelog?.id}`}
          setData={(e: any) => {
            onUpdateItem(changelog.id, {
              content: e,
            })
          }}
          storageFolder="changelog"
          storageObjectIsGlobal={true}
        />
      </div>
    </>
  )
}

export default EditChangelogItem
