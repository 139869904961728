export const PAGINATED_CHANGELOGS_QUERY = gql`
  query FindPaginatedChangeLogs($skip: Int, $limit: Int) {
    changelogs(skip: $skip, limit: $limit) {
      nodes {
        ...ChangelogFields
      }
      hasNextPage
    }
  }
`

export const CREATE_CHANGELOG_ITEM = gql`
  mutation CreateChangelog($input: CreateChangelogInput!) {
    createChangelog(input: $input) {
      ...ChangelogFields
    }
  }
`

export const UPDATE_CHANGELOG_ITEM = gql`
  mutation UpdateChangelog($id: Int!, $input: UpdateChangelogInput!) {
    updateChangelog(id: $id, input: $input) {
      ...ChangelogFields
    }
  }
`

export const DELETE_CHANGELOG_ITEM = gql`
  mutation DeleteChangelog($id: Int!) {
    deleteChangelog(id: $id) {
      id
    }
  }
`
