import { FLAG_FEATURES } from 'api/src/common/enums'

import { Metadata } from '@redwoodjs/web'

import Changelog from 'src/components/Changelog/Changelog'
import FeatureDenied from 'src/components/Library/FeatureDenied/FeatureDenied'
import FeatureToggle from 'src/lib/hooks/LiveFeatureFlag/FeatureToggle'

const ChangelogPage = () => {
  return (
    <>
      <Metadata title="Release Notes" description="Release Notes" />
      <FeatureToggle
        feature={FLAG_FEATURES.CHANGELOG}
        InactiveComponent={<FeatureDenied />}
      >
        <div className="bg-white">
          <Changelog />
        </div>
      </FeatureToggle>
    </>
  )
}

export default ChangelogPage
